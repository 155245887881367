@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");
/* :root {
  --primary: #ff0000;
} */

body {
  background-color: #011627;
  margin: 0;
  padding: 0;
}
