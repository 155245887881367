.about_body {
  height: 85vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.grid_container {
  width: 100%;
  height: 100%;
}
.grid_item {
  border-right: 0.25px solid #1e2d3d;
  text-align: center;
  padding-top: 15px;
}
.about_text {
  font-family: "Fira Code", monospace !important;
  color: #e5e9f0;
  font-size: 16px !important;
}
.about_text_folder {
  font-family: "Fira Code", monospace !important;
  color: #607b96;
  font-size: 16px !important;
}
.about_text_folder_under {
  font-family: "Fira Code", monospace !important;
  color: #607b96;
  font-size: 13px !important;
}

.grid_item_inner {
  border-right: 0.25px solid #1e2d3d;
  text-align: left;
}
.about_numbers {
  color: #607b96;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
}
.about_info {
  color: #607b96;
  font-family: "Fira Code", monospace !important;
  font-size: 13px !important;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
}
.about_info_small {
  color: #607b96;
  font-family: "Fira Code", monospace !important;
  font-size: 16px !important;
  word-spacing: 5%;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
}

::-webkit-scrollbar {
  width: 12.5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #607b96;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #607b96;
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e2d3d;
}

.project_container {
  padding: 8%;
}
