.about_body {
  height: 85vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.grid_container {
  width: 100%;
  height: 100%;
}
.grid_item {
  border-right: 0.25px solid #1e2d3d;
  text-align: center;
  padding-top: 15px;
}
.about_text {
  font-family: "Fira Code", monospace !important;
  color: #e5e9f0;
  font-size: 16px !important;
}
.about_text_folder {
  font-family: "Fira Code", monospace !important;
  color: #607b96;
  font-size: 16px !important;
}
.about_text_folder_under {
  font-family: "Fira Code", monospace !important;
  color: #607b96;
  font-size: 13px !important;
}

.grid_item_inner {
  border-right: 0.25px solid #1e2d3d;
  text-align: left;
}
.about_numbers {
  color: #607b96;
  user-select: none;
  font-family: "Fira Code", monospace !important;
  font-size: 18px !important;
}
.about_info {
  color: #607b96;
  font-family: "Fira Code", monospace !important;
  font-size: 13px !important;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
}
.contact_keyword {
  color: #73c6e7;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
  /* padding-right: 10px; */
}
.contact_object {
  color: #8bdfae;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
}
.contact_method {
  color: #cfea94;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
}
.contact_invoke {
  color: #8bc6df;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
}
/* .contact_other {
  color: #5565e8;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important; 
} */
.contact_variable {
  color: #71a0f1;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
}
.contact_string {
  color: #fea55f;
  font-family: "Fira Code", monospace !important;
  font-size: 14px !important;
}
.contact_text {
  font-family: "Fira Code", monospace !important;
  border: 1px solid #607b96;
  outline: none;
  resize: none;
  border-radius: 8px;
  color: #91b1d0;
  height: 145px;
  padding: 10px;
  width: 100%;
  background-color: #011627;
}
.contact_small {
  font-family: "Fira Code", monospace !important;
  border: 1px solid #607b96;
  outline: none;
  border-radius: 8px;
  color: #91b1d0;
  height: 41px;
  padding: 0 10px;
  width: 100%;
  background-color: #011627;
}

.contact_small:-webkit-autofill,
.contact_small:-webkit-autofill:hover,
.contact_small:-webkit-autofill:focus,
.contact_small:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px #011627 inset !important;
  -webkit-text-fill-color: #91b1d0 !important;
}
.about_info_small {
  color: #607b96;
  font-family: "Fira Code", monospace !important;
  font-size: 16px !important;
  word-spacing: 5%;
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 5px;
}
.about_overflow {
  width: 100%;
}
::-webkit-scrollbar {
  width: 12.5px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #607b96;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #607b96;
  height: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #1e2d3d;
}

.project_container {
  padding: 8%;
}

.contatct_container {
  /* width:100%;
      height:auto; */
  padding-left: 15%;
  padding-right: 15%;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  /* padding-left: 10%;
      padding-right: 10%; */
}
.contatct_container_thanks {
  /* width:100%;
    height:auto; */

  padding-left: 15%;
  padding-right: 15%;
  padding-top: 30%;
  padding-bottom: 5%;
  text-align: center;
  /* padding-left: 10%;
    padding-right: 10%; */
}
.submitted_thanks {
  font-family: "Fira Code";
  font-style: normal;
  font-weight: 450;
  font-size: 26px;
  line-height: 34px;
  text-align: center;
  padding-bottom: 5%;
  color: #ffffff;
}
.form_text {
  font-family: "Fira Code", monospace !important;
  color: #607b96;
  font-size: 16px !important;
  padding-bottom: 5%;
  text-align: left;
}
.error_message {
  font-family: "Fira Code", monospace !important;
  color: #b34d4d;
  font-size: 12px !important;
  text-align: left;
  margin-bottom: 10px;
}

.contact_button {
  width: 146px;
  height: 38px;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: #1c2b3a;
  text-align: center;
  color: white;
  font-family: "Fira Code", monospace !important;
  /* font-size: 12px !important; */
}

.contact_button:enabled:hover {
  cursor: pointer;
  background-color: #141e2b;
}
.contact_button_text {
  outline: none;
  border: none;
  border-radius: 8px;
  text-align: left;
  color: #607b96;
  font-size: 10px !important;
  font-family: "Fira Code", monospace !important;
}
.button_loader {
  animation: rotateIcon 1.2s linear infinite;
}
@keyframes rotateIcon {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
