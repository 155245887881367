.proj {
  width: 100%;
  height: 314.82px;
  background-color: #011221;
  border-radius: 15px;
  position: relative;
  z-index: 1;
  border: 1px solid #0c2c49;
}
.proj_title {
  font-family: "Fira Code", monospace !important;
  color: #5565e8;
  font-size: 16px;
  font-weight: 700;
}
.proj_name {
  font-family: "Fira Code", monospace !important;
  color: #6f88a3;
  font-size: 16px;
}
.proj_icon {
  position: absolute;
  top: 5%;
  left: 80%;
  width: 28px;
  height: 28px;
  display: flex;
  background-color: #9cd7bc;
  border-radius: 2px;
  justify-content: center;
  align-items: center;
}
