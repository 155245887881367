@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");

.nav_container {
  height: 7vh;
  border-top: 0.5px solid #1e2d3d;
  width: 100%;
  position: sticky;
  bottom: 0;
}

.name {
  color: #607b96;
  text-align: center;
  padding-top: 7%;
  font-family: "Fira Code", monospace !important;
}
.nav_links {
  text-align: center;
  color: #607b96;
  height: 100%;
  position: relative;
  border-left: 0.5px solid #1e2d3d;
}

.nav_link_small {
  text-align: left;
  color: #ffffff;
  border-top: 0.25px solid #1e2d3d;
  border-bottom: 0.25px solid #1e2d3d;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 7%;
  font-family: "Fira Code", monospace !important;
}
.nav_link_name {
  color: #607b96;
  text-align: center;
  padding-top: 5%;
  font-family: "Fira Code", monospace !important;
}

.nav_links:hover {
  cursor: pointer;
}
