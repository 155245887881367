.dots {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;

  animation: dots_around 15s linear infinite;
}
@keyframes dots_around {
  0% {
    top: 50%;
    left: 0;
  }
  25% {
    top: 95%;
    left: 95%;
  }
  50% {
    top: 0%;
    left: 55%;
  }
  100% {
    top: 95%;
    left: 0;
  }
}

.dots2 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around2 15s linear infinite;
}
@keyframes dots_around2 {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 95%;
    left: 0;
  }
  50% {
    top: 0%;
    left: 95%;
  }
  100% {
    top: 95%;
    left: 0;
  }
}
.dots3 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around3 15s linear infinite;
}
@keyframes dots_around3 {
  0% {
    bottom: 0;
    right: 0;
  }
  25% {
    bottom: 95%;
    right: 50%;
  }
  50% {
    bottom: 0%;
    right: 95%;
  }
  100% {
    bottom: 95%;
    right: 0;
  }
}
.dots4 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around4 5s linear infinite;
}
@keyframes dots_around4 {
  0% {
    bottom: 0;
    right: 0;
  }
  25% {
    bottom: 95%;
    right: 50%;
  }
  50% {
    bottom: 20%;
    right: 95%;
  }
  100% {
    bottom: 95%;
    right: 0;
  }
}
.dots5 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around5 15s linear infinite;
}
@keyframes dots_around5 {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 95%;
    left: 0;
  }
  50% {
    top: 0%;
    left: 95%;
  }
  100% {
    top: 95%;
    left: 50%;
  }
}
.dots6 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around6 5s linear infinite;
}
@keyframes dots_around6 {
  0% {
    top: 0;
    left: 0;
  }
  25% {
    top: 25%;
    left: 0;
  }
  50% {
    top: 0%;
    left: 25%;
  }
  100% {
    top: 95%;
    left: 0;
  }
}
.dots7 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around7 15s linear infinite;
}
@keyframes dots_around7 {
  0% {
    top: 0;
    right: 0;
  }
  25% {
    top: 95%;
    right: 0;
  }
  50% {
    top: 0%;
    right: 95%;
  }
  100% {
    top: 95%;
    right: 0;
  }
}
.dots8 {
  width: 5px;
  background-color: white;
  opacity: 0.7;
  height: 5px;
  border-radius: 100%;
  position: absolute;
  animation: dots_around8 15s linear infinite;
}
@keyframes dots_around8 {
  0% {
    top: 0;
    right: 0;
  }
  25% {
    top: 25%;
    right: 0;
  }
  50% {
    top: 0%;
    right: 25%;
  }
  100% {
    top: 95%;
    right: 0;
  }
}
