.home_body {
  height: 80vh;
  width: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.grid_container {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: flex-start;
}
.grid_item {
  justify-content: center;
  position: relative;
}
.intro_text {
  font-family: "Fira Code", monospace !important;
  color: #e5e9f0;
  font-size: 18px;
}
.intro_name {
  font-family: "Fira Code", monospace !important;
  color: #e5e9f0;
  font-size: 46px !important;
  font-weight: 600;
}
.intro_comment {
  font-family: "Fira Code", monospace !important;
  color: #607b96;
  font-size: 16px !important;
}
.intro_download {
  width: 155px;
  height: 45px;
  line-height: 45px;
  margin-top: 20px;
  text-decoration: none;
  display: block;
  outline: none;
  border: none;
  border-radius: 8px;
  background-color: #1c2b3a;
  text-align: center;
  color: white;
  font-family: "Fira Code", monospace !important;
}
.intro_download:hover {
  background-color: #192534;
}
.intro_const {
  color: #73c6e7;
  font-size: 16px !important;
}
.intro_git {
  color: #8bdfae;
  padding-left: 10px;
  font-size: 16px !important;
}
.intro_github {
  font-family: "Fira Code", monospace !important;
  padding-top: 3%;
  color: white;
}
.intro_gitlink {
  text-decoration: none;
  padding-left: 10px;
  font-size: 16px !important;
  color: #ecae7e;
}
.home_type {
  color: #4d5bce;
  font-size: 32px;
  font-family: "Fira Code", monospace !important;
}
.hero_image {
  width: 400px;
  height: 400px;
  border-radius: 50%;
  position: absolute;
  top: -200%;
}
.hero_image::after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 105%;
  height: 105%;
  border: 3px dashed #73c6e76e;
  border-radius: 50%;
}
.blur {
  position: absolute;
  width: 454px;
  height: 200px;

  background: #43d9ad;
  opacity: 0.4;
  filter: blur(174px);
  transform: rotate(-94.3deg);
}
.blur_small {
  position: absolute;
  width: 90%;
  height: 200px;
  bottom: 80%;
  z-index: 1;
  background: #43d9ad;
  opacity: 0.4;
  filter: blur(174px);
  transform: rotate(-94.3deg);
}
.other_blur_small {
  position: absolute;
  width: 90%;
  height: 200px;
  bottom: 60%;

  background: #4d5bce;
  opacity: 0.4;
  filter: blur(174px);
  transform: rotate(13.51deg);
}
.other_blur {
  position: absolute;
  width: 90%;
  height: 200px;
  top: 50%;
  left: 30%;

  background: #4d5bce;
  opacity: 0.4;
  filter: blur(174px);
  transform: rotate(13.51deg);
}
