@import url("https://fonts.googleapis.com/css2?family=Fira+Code:wght@300;400;500;600;700&display=swap");

.nav_container {
  height: 8vh;
  border-bottom: 0.5px solid #1e2d3d;
  width: 100%;
}

.name {
  color: #607b96;
  text-align: center;
  padding-top: 7%;
  font-family: "Fira Code", monospace !important;
}
.nav_links {
  text-align: center;
  color: #607b96;
  height: 100%;
  position: relative;
  border-left: 0.5px solid #1e2d3d;
}

.nav_link_small {
  text-align: left;
  color: #ffffff;
  border-top: 0.25px solid #1e2d3d;
  border-bottom: 0.25px solid #1e2d3d;
  font-size: 16px;
  padding-left: 20px;
  padding-top: 7%;
  font-family: "Fira Code", monospace !important;
}
.nav_link_name {
  color: #607b96;
  text-align: center;
  padding-top: 5%;
  font-family: "Fira Code", monospace !important;
}
.nav_link {
  padding-top: 7%;

  font-family: "Fira Code", monospace !important;
}

.nav_active {
  width: 100%;
  height: 3px;
  background-color: #ecae7e;
  position: absolute;
  bottom: 0;
  right: 0;
  visibility: hidden;
}

.nav_actives {
  width: 100%;
  height: 3px;
  background-color: #ecae7e;
  position: absolute;
  bottom: 0;
  right: 0;
  /* visibility:hidden; */
}
.active {
  width: 100%;
  height: 3px;
  background-color: #ecae7e;
  position: absolute;
  bottom: 0;
}
.nav_other {
  color: #968860;
  text-decoration: none;
  font-size: 30px;
}
.nav_other.active {
  color: red;
  background-color: purple;
}
@keyframes nav_active {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
.nav_links:hover {
  cursor: pointer;
}

.nav_links:hover .nav_active {
  visibility: visible;
  animation: nav_active 0.2s linear;
}
.active {
  background-color: #ffffff;
}
